import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { Layout } from 'layouts';

import headerImage from '@assets/services/mobile-first/mobile-first-header.jpg';

const MobileDevelopment = () => {
  return (
    <Layout>
      <Helmet title={'Mobile First'} />
      <PageHero image={headerImage} />
      <Heading title="Mobile First" noHeadingIcon={true}>
        <p>
          Websites? Yes, they are fun until you access them on your mobile
          device and they turn out to be desktop compatible only - which means -
          now you have to scroll to each corner of the web page to the required
          information. Imagine it like this, how bogus it would be where you
          have to move up to the top right corner to go to the search bar and
          this swipe through the entire page just to find out that had a typo in
          your search and now you have to go back to the expedition to find that
          search bar again. Seems like a headache, isn't it? Believe us, it is.
        </p>
        <p>
          But we've got a solution. Our unique algorithms to turn websites
          mobile compatible is well-liked by our clients. In our
          reverse-compatible technique, we develop websites/web apps for mobile
          devices which are by themselves compatible with desktop or laptop or
          any device with any resolution. By this way, we are actually
          developing it for the in-trend devices which in turn ends up working
          for a dying medium (cough, cough; desktop)
        </p>

        <p>
          Let us tell you that mobile-first technology should not be confused
          with mobile responsiveness. Mobile responsive and mobile-first have
          some of the same ingredients, but their methods, approaches, and
          strategies are totally different.
        </p>

        <p>
          Mobile responsive is a technical web design approach where CSS is used
          to adjust the website to the device it is viewed on. Whereas in
          mobile-first technology, instead of creating a desktop website and
          then forcing it to fit in a mobile box, you create a website that
          considers the majority of users (on mobile) first.
        </p>

        <p>
          But again, please do not think of it as - it's going to be less
          efficient for PC users. That’s not the case. Mobile-first platforms
          work flawlessly on PCs as well, in fact, much better than the original
          PC website platforms.
        </p>
      </Heading>
    </Layout>
  );
};

export default MobileDevelopment;
